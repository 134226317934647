import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Paragraph",
  "type": "Typography"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Paragraph`}</strong>{` renders a `}<inlineCode parentName="p">{`p`}</inlineCode>{` element and is used for normal text rendering in the
application. As a typography component it uses the
`}<a parentName="p" {...{
        "href": "https://fonts.google.com/specimen/Source+Sans+Pro"
      }}>{`Source Sans Pro`}</a>{` as a font. There
are not a lot of variations for the `}<strong parentName="p">{`Paragraph`}</strong>{` component, but it will forward all
props provided to it's root `}<inlineCode parentName="p">{`p`}</inlineCode>{` element.`}</p>
    <p><strong parentName="p">{`Paragraph`}</strong>{` element doesn't include any spacing between, so make sure there is one
present.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=false",
        "live": "true",
        "editor": "false"
      }}>{`<Paragraph>A paragraph</Paragraph>
`}</code></pre>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`strong`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Will make the text with strong emphasis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content of the paragraph`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Variations`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true",
        "live": "true"
      }}>{`<>
  <Paragraph>A paragraph</Paragraph>
  <Divider />
  <Paragraph strong>More important paragraph</Paragraph>
</>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      